import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

import icon_smile from '../images/icon_smile.gif'

// http://www.dktech.cz/?lang=cz&paged=16
// http://www.dktech.cz/?p=118#more-118

const PostStehovaniOdstavka: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_vanocni_c4"
      nextLink="/post_podzimni_lov_mysi"
    >
      <PostHeader
        title="Stěhování a odstávka serveru"
        link="/post_vanocni_c4"
        author="Algy"
        date="10.12.2013"
        time="9:00"
        category="Oznámení"
      />
      <h2 style={{ textAlign: 'right' }}>11. 12. 2013, 12:00 hod. – 15. 12. 2013, 20:00 hod.</h2>
      <p>Dobrý den,</p>
      <p style={{ textAlign: 'justify' }}>s politováním ale i potěšením Vám musím oznámit, že ve dnech 11. – 15. prosince 2013&nbsp;proběhne&nbsp;z důvodu stěhování odstávka serveru.</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Ano, téměř vše je již připraveno na dříve avizované stěhování serveru z pražského Gameparku do Brna. Gamepark s koncem letních prázdnin ukončil dohodu o bezplatném provozování našeho serveru. Od té doby jsme nuceni za konektivitu platit ze svých kapes (díky Dah4ku). Po měsících zařizování se podařilo najít nového partnera, který nám nabízí výrazně lepší podmínky, než jsme doposud měli. To s sebou nese samozřejmě vyndání serveru z Cassablanky, odvoz a opětovné zapojení. V průběhu akce je třeba zkoordinovat množství lidí tak, aby server neutrpěl poškození a vše proběhlo pokud možno co nejrychleji. I tak se ovšem pravděpodobně nedostaneme pod pět dní. Osobně jsem ale rád, že, zdá se, bude zahnán velký otazník, který nad další existencí serveru od září visel.</p>
      <p><span id="more-118"></span></p>
      <p><span style={{ textDecoration: 'underline' }}>Předpokládaný harmonogram akce:</span></p>
      <p>1/ 11. prosince 2013, 12:00 hodin – <strong>odpojení serveru</strong>, záloha databáze,</p>
      <p>2/ 11. prosince 2013, 19:00 hodin – vyzvednutí serveru s Cassablanky,</p>
      <p>3/ 12. – 14. prosince 2013 – přesun serveru z Prahy do Brna,</p>
      <p>4/ 14. prosince 2013, odpolední hodiny – kontrola a zapojení serveru,</p>
      <p>5/ 15. prosince 2013, 20:00 hodin – předpokládané <strong>spuštění serveru</strong> z nového umístění.</p>
      <p>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Věřte, že budeme dělat vše pro to, aby server běžel co nejdříve a my budeme věřit, že se podaří uvedený harmonogram dodržet <img src={icon_smile} alt=":-)" className="wp-smiley" />  . Přesun s sebou <strong>nepřinese ani wipe, ani up/down grade verze Lineage II.</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Je třeba si uvědomit, že se všichni zainteresovaní v této předvánoční době zúčastní stěhování ze svého volného času, na úkor svých rodin a na vlastní náklady. A patří jim za to obrovský dík! &nbsp;A na závěr je, myslím, na místě poděkovat Gameparku za všechna ta léta, která nám umožňoval bezplatně využívat připojení. &nbsp;Díky.</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Využijte tohoto krátkého herního volna k nákupu dárků, předvánočním úklidům nebo pečení cukroví. A 15. prosince 2013 večer na viděnou.</p>
      <p style={{ textAlign: 'justify' }}>
      </p><p>S pozdravem a přáním všeho dobrého za DKTech tým Algy.</p>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default PostStehovaniOdstavka

export { Head } from '../components/defaultHtmlHead'